<template>
  <v-container
    style="background-color: white; height: 100%;"
    class="pa-0"
  >
    <v-card
      height="100%"
      flat
    >
      <v-toolbar
        flat
        width="100%"
        class="toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$emit('close')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t('actions|add_document') }}
        </v-toolbar-title>
      </v-toolbar>

      <div class="px-4">
        <document-list
          :documents="filteredPersonalDocuments"
          :list-group="selectedDocuments"
          :toolbar="{
            hasClose: false
          }"
          :from-case-view="false"
          :is-flat="true"
          :from-workbench="true"
          class="flex-grow-1 pb-8"
          @search="setInput"
          @updateGroup="updateGroup"
        />
      </div>
    </v-card>
    <v-toolbar
      flat
      color="primary"
      class="bottom-element-add-docs-to-workbench"
    >
      <v-spacer />
      <v-btn
        rounded
        outlined
        dark
        small
        min-width="150px"
        @click="selectDocumentAction"
      >
        {{ $t('actions|submit') }}
      </v-btn>
      <v-spacer />
    </v-toolbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { uniqBy } from '@/utils/utils'

export default {
  props: {
    caseDocuments: {
      type: Array,
      default: () => ([])
    },
    actualCase: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    userRole: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedDocuments: [],
      searchResult: ''
    }
  },
  computed: {
    filteredPersonalDocuments () {
      if (this.searchResult && this.searchResult.length && this.caseDocuments.length) {
        return this.caseDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.caseDocuments
    }
  },
  methods: {
    ...mapActions({
      updateCaseWorkbench: 'cases/updateCaseWorkbench',
      addToast: 'toasts/addToast'
    }),
    updateGroup (index) {
      this.selectedDocuments = index
    },
    async selectDocumentAction () {
      let selectedDocuments = []
      this.caseDocuments.filter((doc, index) => {
        if (this.selectedDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      try {
        const documents = uniqBy([...this.workbench.assignedDocuments, ...selectedDocuments], '_id')
        let docIdsToBeSend = []
        documents.forEach(doc => {
          docIdsToBeSend.push(doc._id)
        })
        this.updateCaseWorkbench({
          _id: this.actualCase._id,
          workbenchId: this.workbench._id,
          payload: {
            assignedDocuments: docIdsToBeSend
          }
        })
        this.$emit('close')
        this.addToast({
          title: this.$t('common|added_successfully'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    setInput (input) {
      this.searchResult = input
    }
  }
}
</script>

<style scoped>
  .toolbar {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 48px;
    z-index: 1;
  }
  .bottom-element-add-docs-to-workbench {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
